import TextFooterStyle from './style';

function TextFooter(props) {
  return (
    <TextFooterStyle color={props.color} page={props.page}>
      <p>© 2021 Seed a Bit Tecnologia - Todos os direitos reservados</p>
    </TextFooterStyle>
  );
}
export default TextFooter;
