import { useContext } from 'react';
import { MenuContext } from '../../../../contexts/MenuContext';

import { Container, Banner, ProductWrapper } from './style';

import ProductCategory from '../../../../components/ProductCategory';

function SectionMain() {
  const { getCategories } = useContext(MenuContext);
  const categories = getCategories();
  return (
    <Container>
      <Banner>
        <h1>Nosso cardápio</h1>
        <p>Clique em um dos botões a seguir para ver as nossas opções.</p>
        <ProductWrapper>
          {categories.map((category) => {
            return (
              <a href={"#"+category.id}>
                <ProductCategory
                  key={category.id}
                  img={category.imgUrl}
                  title={category.title}
                />
              </a>
            );
          })}
        </ProductWrapper>
      </Banner>
    </Container>
  );
}

export default SectionMain;
