import { createContext, useState, useEffect } from 'react';
import cartRepository from '../../repositories/cartRepository';

const initialState = {
  getCategories: () => {},
  getItemsFromCategory: () => {},
  getMenu: () => {},
  addItemToCart: () => {},
  decreaseItemFromCart: () => {},
  getCart: () => {},
  getItemQuantityInCart: () => {},
};

export const MenuContext = createContext(initialState);

export function MenuContextProvider({ children }) {
  const [categories, setCategories] = useState([]);
  const [menu, setMenu] = useState([]);
  const [cart, setCart] = useState([]);
  const [menuItems, setMenuItems] = useState([]);

  useEffect(async () => {
    const response = await fetch(`${process.env.PUBLIC_URL}/data/db.json`);
    const data = await response.json();
    setMenu(() => [...data.menu]);
    setCategories(() =>
      data.menu.map((category) => {
        return {
          id: category.id,
          imgUrl: category.imgUrl,
          title: category.title,
        };
      })
    );
    setMenuItems(() => {
      const temp = [];
      data.menu.forEach((category) => {
        temp.push(...category.products);
      });
      return temp;
    });
  }, []);

  useEffect(() => {
    const cartFromStorage = cartRepository.getCart();
    if (cartFromStorage) {
      setCart(cartFromStorage);
    }
  }, []);

  useEffect(updateCart, [cart]);

  function updateCart() {
    cartRepository.saveCart(cart);
  }

  function getCategories() {
    return categories;
  }

  function getMenu() {
    return menu;
  }

  function getCart() {
    return cart;
  }

  function getItemFromMenu(itemId) {
    return menuItems.filter((item) => {
      return item.id === itemId;
    })[0];
  }

  function existsInCart(itemId) {
    return cart.some((item) => item.id === itemId);
  }

  function getItemFromCart(itemId) {
    return cart.filter((item) => item.id === itemId)[0];
  }

  function increaseItemQuantityInCartByOne(itemId) {
    setCart((oldCart) => {
      return [
        ...oldCart.map((item) => {
          if (item.id === itemId) {
            return {
              ...item,
              quantity: item.quantity + 1,
            };
          }
          return item;
        }),
      ];
    });
  }

  function insertItemInCart(itemId) {
    const item = getItemFromMenu(itemId);
    if (item) {
      setCart((oldCart) => {
        return [
          ...oldCart,
          {
            ...item,
            quantity: 1,
          },
        ];
      });
    }
  }

  function addItemToCart(itemId) {
    if (existsInCart(itemId)) {
      return increaseItemQuantityInCartByOne(itemId);
    }
    insertItemInCart(itemId);
  }

  function decreaseItemQuantityInCartByOne(itemId) {
    setCart((oldCart) => {
      return [
        ...oldCart.map((item) => {
          if (item.id === itemId) {
            return {
              ...item,
              quantity: item.quantity - 1,
            };
          }
          return item;
        }),
      ];
    });
  }

  function removeItemFromCart(itemId) {
    setCart((oldCart) => {
      return oldCart.filter((item) => !(item.id === itemId));
    });
  }

  function decreaseItemFromCart(itemId) {
    const item = getItemFromCart(itemId);
    if (!item || !existsInCart(itemId)) {
      return;
    }
    if (item.quantity > 1) {
      return decreaseItemQuantityInCartByOne(item.id);
    }
    removeItemFromCart(item.id);
  }

  function getItemsFromCategory(categoryId) {
    const wantedCategory = menu.filter((category) => {
      return category.id === categoryId;
    })[0];
    return wantedCategory.products;
  }

  function getItemQuantityInCart(itemId) {
    if (!existsInCart(itemId)) {
      return 0;
    }
    const item = getItemFromCart(itemId);
    return item.quantity;
  }

  return (
    <MenuContext.Provider
      value={{
        getCategories,
        getItemsFromCategory,
        getMenu,
        getCart,
        addItemToCart,
        decreaseItemFromCart,
        getItemQuantityInCart,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
}
