import {
  Container,
  Wrap,
  Ada,
  ImgCloud1,
  ImgCloud2,
  ImgCloud3,
  ImgCloud4,
  ImgCloud5
} from './style.js';

import Cloud from '../../../../assets/images/Cloud.png';
import ImgAda from '../../../../assets/images/Ada.png';

function SectionAda() {
  return (
    <Container>
      <Wrap>
        <Ada src={ImgAda} />
      </Wrap>
      
      <div>
        <ImgCloud1 src={Cloud} />
      </div>

      <div>
        <ImgCloud2 src={Cloud} />
      </div>

      <div>
        <ImgCloud3 src={Cloud} />
      </div>

      <div>
        <ImgCloud4 src={Cloud} />
      </div>

      <div>
        <ImgCloud5 src={Cloud} />
      </div>

    </Container>
  );
}

export default SectionAda;
