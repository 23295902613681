import styled from 'styled-components';

import SectionPic from '../../../../assets/images/background-main-cardapio.png';

export const Container = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-image: url(${SectionPic});
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
`;

export const Banner = styled.div`
  background-color: var(--white);
  border-radius: var(--rounded-border-three);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  a {
    color: var(--black);
  }

  padding: 2rem;

  h1,
  p {
    text-align: center;
  }

  @media (min-width: 1000px) {
    padding: 6rem 10rem;
  }
`;

export const ProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  a {
    text-decoration: none;
  }

  a:visited {
    color: var(--black);
  }

  @media (min-width: 1000px) {
    flex-direction: row;
    div {
      margin: 2rem 1rem;
    }
  }
`;
