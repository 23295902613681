import { ListNav, MenuMobile } from './style';
import Button from '../../../../components/Button/index';
import Menu from '../../../../assets/images/menu.png';
import { useState } from 'react';
import { Link } from 'react-router-dom';

function ContentNavbar() {
  const [state, setState] = useState(true);

  function hideMenu() {
    var list = document.getElementById('list');

    if (window.innerWidth <= 800) {
      if (state) {
        list.style.display = 'block';
      } else {
        list.style.display = 'none';
      }
    }
    setState(!state);
  }
  return (
    <>
      <MenuMobile src={Menu} onClick={hideMenu} />
      <ListNav id="list">
        <li>
          <a href="#schedule" onClick={hideMenu}>
            <h2>Horários</h2>
          </a>
        </li>
        <li>
          <a href="#paymentMethods" onClick={hideMenu}>
            <h2>Pagamento</h2>
          </a>
        </li>
        <li>
          <a href="#deliveryPoints" onClick={hideMenu}>
            <h2>Entrega</h2>
          </a>
        </li>
        <li>
          <Link to="/cardapio">
            <Button id="navButton" color="green" small>
              Cardápio
            </Button>
          </Link>
        </li>
      </ListNav>
    </>
  );
}

export default ContentNavbar;
