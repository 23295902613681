import { Container, Background, Image } from './style';

function PaymentMethod({ img, title }) {
  return (
    <Container>
      <Background>
        <Image src={img} alt={title} />
      </Background>
      <h2>{title}</h2>
    </Container>
  );
}

export default PaymentMethod;
