import { useContext } from 'react';
import { MenuContext } from '../../contexts/MenuContext';

import {
  Container,
  Image,
  CardHeader,
  Title,
  Price,
  Description,
  CardAmount,
  InputContainer,
  TextBox,
  QtdButton,
} from './style';

function Card({ id, title, price, description }) {
  const { getItemQuantityInCart, decreaseItemFromCart, addItemToCart } =
    useContext(MenuContext);

  return (
    <Container>
      <CardHeader>
        <Title>{title}</Title>

        <Price>R$ {price}</Price>
      </CardHeader>
      <Image />
      <Description>{description}</Description>
      <CardAmount>
        <p>Qtd:</p>
        <InputContainer>
          <QtdButton onClick={() => decreaseItemFromCart(id)} color="less">
            -
          </QtdButton>
          <TextBox value={getItemQuantityInCart(id)} disabled />
          <QtdButton onClick={() => addItemToCart(id)} color="more">
            +
          </QtdButton>
        </InputContainer>
      </CardAmount>
    </Container>
  );
}

export default Card;
