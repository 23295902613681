import {
  Container,
  Wrap,
  Ada,
  ImgCloud1,
  ImgCloud2,
  ImgCloud3,
  Card,
} from './style.js';

import Cloud from '../../../../assets/images/Cloud.png';
import ImgAda from '../../../../assets/images/Ada.png';

function SectionNotFound() {
  return (
    <Container>
      <Card>
        <div>
          <h2>ERRO 404</h2>
          <p>
            Desculpe, a página que você está procurando não foi encontrada.
            <br />
            Ela pode ter mudado de endereço ou ter sido excluída
            permanentemente.
          </p>
        </div>
      </Card>
      <div>
        <div>
          <ImgCloud1 src={Cloud} />
        </div>

        <div>
          <ImgCloud2 src={Cloud} />
        </div>

        <div>
          <ImgCloud3 src={Cloud} />
        </div>

        <Wrap>
          <Ada src={ImgAda} />
        </Wrap>
      </div>
    </Container>
  );
}

export default SectionNotFound;
