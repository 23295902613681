import styled from 'styled-components';

export const ModalStyle = styled.div`
  width: 18.75rem;
  height: 11.5rem;
  font-size: 0.8rem;

  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: translateY(10px);

  background-color: var(--gray-one);
  border-radius: 10px;

  @media (min-width: 600px) {
    width: 25.75rem;
    height: 13.5rem;
    font-size: 1.1rem;
  }

  p {
    text-align: center;
    margin: 1.2rem 1rem 1rem;
  }
  button {
    justify-self: end;
    margin-bottom: 1.4rem;
    transform: translateY(20px);

    color: var(--white);
    border-radius: 5px;
    border: unset;
    cursor: pointer;

    @media (min-width: 600px) {
      font-size: 1rem;
      padding: 0.8rem;
    }
  }
`;

export const Overlay = styled.div`
  position: fixed;
  inset: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(0, 0, 0, 0.7);
`;
