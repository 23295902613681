import styled from 'styled-components';

export const Nav = styled.nav`
  width: 100%;

  font-size: 0.75rem;

  box-shadow: 2px 2px 2px #00000020;

  div {
    display: flex;
    align-items: center;

    max-width: 1400px;
    margin: 0 auto;
  }
`;

export const Logo = styled.img`
  max-width: 70px;
  max-height: 40px;

  margin: 0.2rem 0 0.2rem 1.6rem;

  border-radius: 4px;

  cursor: pointer;

  @media (min-width: 500px) {
    max-width: 75px;
    max-height: 45px;
  }
  @media (min-width: 800px) {
    max-width: 85px;
    max-height: 60px;
    margin-left: 2.5rem;
  }
  @media (min-width: 1000px) {
    margin-left: 7.5rem;
  }
`;
