import Button from '../../../../components/Button/index';
import { useContext } from 'react';
import { MenuContext } from '../../../../contexts/MenuContext/index';
import GetTotalPriceOfCart from '../../../../utils/getTotalPriceOfCart';

function ContentBottomBar() {
  const { getCart } = useContext(MenuContext);
  const cart = getCart();

  return (
    <div>
      Total: <span>R$ {GetTotalPriceOfCart(cart).toFixed(2)}</span>
      <a href="#order">
        <Button color="green">FINALIZAR PEDIDO</Button>
      </a>
    </div>
  );
}

export default ContentBottomBar;
