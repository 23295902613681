import styled from 'styled-components';

export const Container = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 50%;
  right: 0;
  z-index: 10;
  transform: translateY(-50%);

  a:not(a:first-child) {
    margin-top: 1rem;
  }
`;