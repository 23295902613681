import {
  TitleFormsPayment,
  ContainerPayment,
  TitleFlags,
  ContainerFlags,
} from './style';

import MercadoPago from '../../../../assets/images/mercado-pago.png';
import Dinheiro from '../../../../assets/images/dinheiro.png';
import Cartao from '../../../../assets/images/cartao.png';
import Pix from '../../../../assets/images/pix.png';
import Visa from '../../../../assets/images/bandeira-visa.png';
import Master from '../../../../assets/images/bandeira-master.png';
import Hiper from '../../../../assets/images/bandeira-hiper.png';
import Elo from '../../../../assets/images/bandeira-elo.png';

import PaymentMethod from '../../../../components/PaymentMethod';

function PaymentMethods() {
  return (
    <>
      <TitleFormsPayment id="paymentMethods">
        FORMAS DE PAGAMENTO
      </TitleFormsPayment>
      <ContainerPayment>
        <PaymentMethod img={Dinheiro} title="Dinheiro" alt="Dinheiro" />
        <PaymentMethod img={Cartao} title="Cartão" alt="Cartão de crédito" />
        <PaymentMethod img={Pix} title="Pix" alt="Pix" />
        <PaymentMethod
          img={MercadoPago}
          title="Mercado Pago"
          alt="Mercado Pago"
        />
      </ContainerPayment>

      <TitleFlags>Aceitamos as seguintes bandeiras</TitleFlags>
      <ContainerFlags>
        <img src={Visa} alt="Visa" />
        <img src={Master} alt="Master" />
        <img src={Hiper} alt="Hiper" />
        <img src={Elo} alt="Elo"/>
      </ContainerFlags>
    </>
  );
}

export default PaymentMethods;
