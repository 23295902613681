import styled from 'styled-components';

export const MenuMobile = styled.img`
  margin-left: auto;
  margin-right: 1rem;
  cursor: pointer;

  @media (min-width: 500px) {
    width: 30px;
  }
  @media (min-width: 800px) {
    display: none;
  }
`;

export const ListNav = styled.ul`
  display: none;
  padding: 0;
  width: 100%;
  position: absolute;
  margin: -100% 0 0 0;

  animation: slide 0.4s ease-out forwards;
  background-color: #ffffff;

  @media (min-width: 800px) {
    display: flex !important;
    width: auto;
    margin-left: auto;
  }

  li {
    width: min-content;

    display: flex;
    justify-content: center;
    justify-self: center;
    list-style: none;
    margin: 1.5rem auto;
    a {
      color: var(--black);
      text-decoration: none;
      transition: ease-in 0.2s;
      h2 {
        cursor: pointer;

        @media (min-width: 800px) {
          display: static;

          font-size: 1.2rem;
          margin: 0 0.4rem;
          align-self: center;
        }
      }
    }

    a:hover{ 
      color: var(--primary-color-hover);
    }

    @media (min-width: 800px) {
      margin: 0 2rem;
      align-self: center;
    }
  }
  & button {
    cursor: pointer;

    @media (min-width: 800px) {
      font-size: 1rem;
      padding: 1rem 1.5rem;
    }
  }
  @media (min-width: 800px) {
    display: flex;
    align-items: center;
    margin: 0 0 0 auto;
    position: static;

    animation: none;
  }
  @keyframes slide {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
      margin-top: 21.829rem;
      box-shadow: 3px 3px 3px #000;
    }
  }
`;
