import styled from 'styled-components';

export const Container = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  background-color: var(--gray-three);
  position: relative;
  z-index: 30;

  h2 {
    font-size: 2rem;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 800px) {
    flex-direction: row;
    div,
    img {
      padding: 0 2rem;
    }
  }
`;

export const InputWrapper = styled.form`
  margin-bottom: 1.5rem;

  p {
    text-align: center;
    margin-bottom: 1.5rem;
  }

  p,
  label {
    margin: 0 0 0.3rem 0;
  }

  input,
  textarea {
    margin-bottom: 1rem;
    border: solid 1px var(--gray-five);
    width: 98% !important;
    border-radius: 5px;
    outline-color: var(--primary-color);
  }
  input {
    height: 1.6rem;
  }

  textarea {
    resize: vertical;
  }
  @media (min-width: 800px) {
    width: 25%;
  }
`;

export const Img = styled.img`
  width: 20rem;

  @media (min-width: 800px) {
    width: 25rem;
  }
`;
