import styled from 'styled-components';

export const Container = styled.section`
  background-color: var(--secondary-color);
  display: flex;
  flex-direction: column;
`;

export const FlexDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem;

  h1 {
    font-size: 1.125rem;
    text-transform: uppercase;
    margin-bottom: 4rem;
  }

  @media (min-width: 1000px) {
    flex-direction: row-reverse;
    justify-content: center;
    padding: 6rem 3rem;

    h1 {
      font-size: 1.5rem;
    }

    > div {
      margin-left: 3vw;
    }

    > img {
      margin-right: 3vw;
    }
  }
`;

export const HorariosDiv = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: row;
  justify-content: center;

  ul {
    list-style: none;
    font-weight: 600;
    margin: 0;
    padding: 0;
  }

  ul:first-child {
    text-align: end;
  }

  ul:last-child {
    text-align: start;
  }

  div {
    align-self: stretch;
    border-right: 3px solid black;
    margin: 0 1.5rem;
  }
`;

export const Image = styled.img`
  width: 18rem;

  @media (min-width: 1000px) {
    width: 25rem;
  }
`;
