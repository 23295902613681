import styled from 'styled-components';

export const Container = styled.div`
  display: none;
  position: fixed;
  left: 0;
  bottom: 0;

  background-color: #f4f4f4;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);

  height: auto;
  width: 100%;
  padding: 1rem 0;

  z-index: 10;

  span {
    margin-right: 2rem;
    font-size: 1.3rem;
    font-weight: bold;
  }

  @media (min-width: 800px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
