import styled from 'styled-components';

export const Container = styled.div`
  box-sizing: border-box;
  overflow: hidden;
  background: #ffffff;
  width: 100%;
  z-index: 10;
`;

export const Wrap = styled.div`
  width: fit-content;
  height: fit-content;

  margin: auto;
  position: relative;
  bottom: -15rem;

  z-index: 2;
`;

export const Ada = styled.img`
  width: 5rem;
  height: auto;

  @media (min-width: 600px) {
    width: 7rem;
  }
`;

export const ImgCloud1 = styled.img`
  width: 28%;
  max-width: 13rem;
  min-width: 9.3rem;
  position: relative;
  top: -7rem;

  animation: slidein 20s linear infinite;
  z-index: 1;

  @keyframes slidein {
    from {
      left: -180px;
    }

    to {
      left: 100%;
    }
  }
`;

export const ImgCloud2 = styled.img`
  width: 18.7%;
  max-width: 9rem;
  min-width: 6.5rem;
  position: relative;
  top: -5rem;

  animation: slidein 30s linear infinite;
  z-index: 2;

  @keyframes slidein {
    from {
      left: -180px;
    }

    to {
      left: 100%;
    }
  }
`;

export const ImgCloud3 = styled.img`
  width: 15%;
  max-width: 7rem;
  min-width: 5rem;
  position: relative;

  animation: slidein-reverse 15s linear infinite;
  z-index: 3;
  top: -2rem;

  @keyframes slidein-reverse {
    from {
      left: 100%;
    }

    to {
      left: -100px;
    }
  }
`;

export const ImgCloud4 = styled.img`
  width: 7.5%;
  max-width: 3.5rem;
  min-width: 2.5rem;
  position: relative;

  animation: slidein-reverse 25s linear infinite;
  z-index: 3;
  top: -10rem;

  @keyframes slidein-reverse {
    from {
      left: 100%;
    }

    to {
      left: -100px;
    }
  }
`;

export const ImgCloud5 = styled.img`
  width: 6.25%;
  max-width: 2.8rem;
  min-width: 2rem;
  position: relative;
  top: -5rem;

  animation: slidein 20s linear infinite;
  z-index: 3;

  @keyframes slidein {
    from {
      left: -180px;
    }

    to {
      left: 100%;
    }
  }
`;
