import Navbar from '../../components/Navbar';
import SectionMain from './components/SectionMain';
import SectionHorarios from './components/SectionHorarios';
import SectionPaymentMethods from './components/SectionPaymentMethods';
import TextFooter from '../../components/TextFooter';
import SectionDeliveryPoints from './components/SectionDeliveryPoints';
import SectionAda from './components/SectionAda';

import BottomBar from '../../components/BottomBar';
import ContentNavbar from './components/ContentNavbar';
import ContentBottomBar from './components/ContentBottomBar/index';

import FloatButtonHome from './components/FloatButtonHome';
import RelativeContainer from '../../components/RelativeContainer';

function Home() {
  return (
    <>
      <Navbar>
        <ContentNavbar />
      </Navbar>
      <SectionMain />
      <RelativeContainer>
        <FloatButtonHome />
        <SectionHorarios />
        <SectionPaymentMethods />
        <SectionDeliveryPoints />
        <SectionAda />
      </RelativeContainer>
      <BottomBar>
        <ContentBottomBar />
      </BottomBar>
      <TextFooter color="white" page="home"/>
    </>
  );
}

export default Home;
