import { useContext, useRef, useState } from 'react';
import { MenuContext } from '../../../../contexts/MenuContext';

import { Container, ContentWrapper, InputWrapper, Img } from './style';
import Image from '../../../../assets/images/finalizeorder.png';
import WaveMenu from '../../../../assets/images/wave-finalize-order.png';
import Modal from '../../../../components/Modal';

import Wave from '../../../../components/Wave/index.js';
import Button from '../../../../components/Button';

function SectionOrder() {
  const [openModal, setOpenModal] = useState('');
  const { getCart } = useContext(MenuContext);
  const cart = getCart();

  const nameRef = useRef(null);
  const addressRef = useRef(null);

  function isEmptyRef(ref) {
    if (ref.current) {
      return ref.current.value.trim().length === 0;
    } else {
      return true;
    }
  }

  function order(event) {
    event.preventDefault();
    if (isEmptyRef(nameRef) || isEmptyRef(addressRef)) {
      setOpenModal('Preencha todos os campos!');
      return;
    } else if (cart.length === 0) {
      setOpenModal(
        'Adicione ao menos um item ao carrinho antes de fechar a compra!'
      );
      return;
    } else {
      var products = cart.map((product) => {
        return `- ${product.title} (x${product.quantity})`;
      });
      var order = `Nome: ${nameRef.current.value}%0AEndereço: ${
        addressRef.current.value
      }%0A%0A${products.join('%0A')}`;
      window.open(`https://wa.me/<INSIRA_NUMERO_AQUI>?text=${order}`, '_blank');
    }
  }
  function handleIsOpen() {
    setOpenModal('');
  }
  function showModal() {
    if (openModal) {
      return (
        <Modal handleIsOpen={handleIsOpen}>
          <p>{openModal}</p>
        </Modal>
      );
    }
  }

  return (
    <>
      <Wave src={WaveMenu} menu />
      <Container id="order">
      {showModal()}
        <h2>Finalize seu Pedido</h2>
        <ContentWrapper>
          <InputWrapper onSubmit={order}>
            <p>
              Os dados abaixo serão enviados junto com as informações do seu
              pedido pelo nosso WhatsApp! Continuaremos nossa conversa por lá,
              ok?
            </p>
            <label to="name">Nome:</label>
            <input type="text" id="name" ref={nameRef}  maxlength="50" />
            <label to="address">Endereço:</label>
            <textarea type="text" id="address" ref={addressRef} maxlength="150" />
            <Button type="submit" stretched>
              Enviar
            </Button>
          </InputWrapper>
          <Img src={Image} alt="" />
        </ContentWrapper>
      </Container>
    </>
  );
}

export default SectionOrder;
