import React from 'react';
import { DeliveryPoints, WrapperContent, Image } from './style';
import MotoBoy from '../../../../assets/images/moto-boy.png';
import Wave from '../../../../components/Wave/index.js';
import WaveTop from '../../../../assets/images/section-delivery-points-wave-top.png';
import WaveBottom from '../../../../assets/images/section-delivery-points-wave-bottom.png';

function SectionDeliveryPoints() {
  return (
    <DeliveryPoints id="deliveryPoints">
      <Wave src={WaveTop} />
      <WrapperContent>
        <div>
          <div>
            <h3>ONDE ENTREGAMOS</h3>
            <p>Lorem ipsum dolor</p>
            <p>Consectetur adipiscing</p>
            <p>Incididunt labore</p>
            <p>Vivamus luctus</p>
          </div>
          <Image src={MotoBoy} alt="Moto boy" />
        </div>
      </WrapperContent>
      <Wave src={WaveBottom} />
    </DeliveryPoints>
  );
}

export default SectionDeliveryPoints;
