import { Link } from 'react-router-dom';
import { Container, Image } from './style';

import Button from '../../../../components/Button';

import SectionPic from '../../../../assets/images/section-main.png';

function SectionMain() {
  return (
    <Container>
      <div>
        <h1>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
          luctus.
        </h1>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
          fringilla elit at sapien tempus iaculis. Donec tempus in justo quis
          lobortis. Donec pellentesque enim ut.
        </p>
        <Link to="cardapio">
          <Button>VER CARDÁPIO</Button>
        </Link>
      </div>
      <Image src={SectionPic} alt="Imagem Principal" />
    </Container>
  );
}

export default SectionMain;
