import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h2 {
    font-size: 1rem;
    @media (min-width: 800px) {
      font-size: 1.5rem;
    }
  }
`;

export const Background = styled.div`
  height: 5rem;
  width: 5rem;
  background-color: var(--gray-six);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 800px) {
    height: 8rem;
    width: 8rem;
  }
`;

export const Image = styled.img`
  width: 3.5rem;

  @media (min-width: 800px) {
    width: 5rem;
  }
`;
