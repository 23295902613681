import styled from 'styled-components';

export const DeliveryPoints = styled.section`
  width: 100%;

  display: flex;
  flex-direction: column;
  margin-top: 1rem;

  background-color: var(--white);

  @media (min-width: 800px) {
    margin-top: 2rem;
  }
`;

export const WrapperContent = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  background-color: var(--secondary-color);
  div {
    width: 100%;
    max-width: 1440px;

    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    align-items: center;
    justify-content: center;

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 2rem;

      h3 {
        text-align: center;
        white-space: nowrap;
      }
      p {
        white-space: nowrap;
        width: min-content;
        margin: 0;
        text-align: center;
        font-size: 0.875rem;
        padding: 0.5rem 0;
      }
    }
    @media (min-width: 800px) {
      flex-direction: row;
      padding: 3rem 0 4.5rem;
      div {
        margin-left: 3rem;
        h3 {
          font-size: 1.2rem;
        }
        p {
          width: 60%;
          font-size: 1rem;
        }
      }
      @media (min-width: 1000px) {
        div {
          width: 50%;
          h3 {
            font-size: 1.5rem;
          }
          p {
            margin: 0;
            text-align: center;
            font-size: 1.1rem;
            padding: 0.5rem 0;
          }
        }
      }
    }
  }
`;

export const Image = styled.img`
  align-self: end;
  margin: 0 auto;
  margin-top: 2.5rem;
  max-width: 13.875rem;
  max-height: 8.875rem;

  @media (min-width: 800px) {
    margin: 0;
    max-width: 23.875rem;
    max-height: 18.875rem;
    align-self: center;
    margin-right: 2rem;
  }
  @media (min-width: 1000px) {
    margin-right: 4rem;
    max-width: 28.875rem;
    max-height: 11.875rem;
  }
  @media (min-width: 1200px) {
    margin-right: 8rem;
    max-width: 33.875rem;
    max-height: 16.875rem;
  }
`;
