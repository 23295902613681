import styled from 'styled-components';

const Wave = styled.img`
  max-height: 70px;
  width: 100%;
  ${(props) => {
    if (props.menu) {
      return 'margin: 50px 0 -30px; position: relative; z-index: 30;';
    }
  }};
`;

export default Wave;
