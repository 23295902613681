import Navbar from '../../components/Navbar/index';
import SectionMain from './components/SectionMain';
import SectionProducts from './components/SectionProducts';
import SectionOrder from './components/SectionOrder';
import BottomBar from '../../components/BottomBar';
import TextFooter from '../../components/TextFooter';
import RelativeContainer from '../../components/RelativeContainer';
import MenuNavigation from './components/MenuNavigation';
import ContentBottomBar from './components/ContentBottomBar';

function Menu() {
  return (
    <>
      <Navbar />
      <SectionMain />
      <RelativeContainer>
        <MenuNavigation />
        <SectionProducts />
      </RelativeContainer>
      <SectionOrder/>
      <BottomBar>
        <ContentBottomBar />
      </BottomBar>
      <TextFooter />
    </>
  );
}

export default Menu;
