import { Container, Image } from './style';

function ProductCategory({ img, title }) {

  return (
      <Container>
        <Image src={img} />
        <p>{title}</p>
      </Container>
  );
}

export default ProductCategory;
