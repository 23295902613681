import styled from 'styled-components';

export const Container = styled.div`
  margin: 1rem 0;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 10rem;
  height: 10rem;

  background-color: var(--white);
  border-radius: var(--rounded-border-two);
  box-shadow: 1px 5px 5px rgba(0, 0, 0, 0.2);

  transition: ease-in .1s;

  img {
    margin-bottom: 1rem;
  }
  
  :hover{
    background: var(--gray-seven);
  }
  
`;

export const Image = styled.img``;
