import styled from 'styled-components';

const TextFooterStyle = styled.footer`
  width: 100%;
  height: 1rem;
  font-size: 0.575rem;
  font-weight: bold;
  line-height: 0.875rem;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0;
  position: relative;
  z-index: 30;

  background-color: ${(props) => {
    if (props.color === 'white') {
      return 'var(--white)';
    }
    return 'var(--gray-four)';
  }};
  color: var(--gray-five);

  @media (min-width: 500px) {
    font-size: 0.7rem;
  }
  @media (min-width: 800px) {
    font-size: 0.875rem;
    margin-bottom: ${(props) => {
      switch (props.page) {
        case 'home':
          return '5rem; z-index: 0;';
        default:
          return '0';
      }
    }};
  }
`;

export default TextFooterStyle;
