import styled from 'styled-components';

export const Container = styled.div`
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 95vh;
  background: #ffffff;
  width: 100%;
  padding: 5rem;
  max-width: 1440px;
  margin: auto;

  @media (min-width: 900px) {
    padding: 5rem 6rem;
  }

  @media (min-width: 1000px) {
    padding: 5rem 7rem;
  }

  @media (min-width: 1400px) {
    padding: 5rem 8rem;
  }
`;

export const Card = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 900px) {
    display: flex;
    align-items: center;
    justify-content: initial;
  }

  div {
    position: relative;
    background-color: #fbfbfb;
    border-radius: 10px;
    padding: 16px;
    text-align: center;
    width: 85%;
    max-width: 500px;
    min-width: 230px;
    z-index: 3;
    line-height: 20px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
  h2 {
    color: #f1b104;
    font-size: 1.75rem;

    @media (min-width: 900px) {
      text-align: left;
      font-size: 2rem;
    }

    @media (min-width: 1000px) {
      font-size: 2.2rem;
    }
  }

  br {
    line-height: 30px;
    @media (min-width: 600px) {
      line-height: 25px;
    }
  }
  p {
    color: #585858;
    font-size: 0.9rem;
    line-height: 20px;

    @media (min-width: 900px) {
      font-size: 1rem;
      text-align: left;
      line-height: 18px;
    }
  }
`;

export const Wrap = styled.div`
  width: fit-content;
  height: fit-content;
  margin: auto;
  position: relative;

  bottom: 20rem;
  z-index: 2;

  @media (min-width: 900px) {
    float: right;
    top: -36rem;
  }
`;

export const Ada = styled.img`
  width: 8rem;
  height: auto;

  @media (min-width: 900px) {
    width: 10rem;
  }

  @media (min-width: 1000px) {
    width: 15rem;
  }
`;

export const ImgCloud1 = styled.img`
  width: 18.7%;
  max-width: 16rem;
  min-width: 10.5rem;

  position: relative;
  top: -15rem;
  animation: slidein 20s linear infinite;
  z-index: 1;

  @keyframes slidein {
    from {
      left: -250px;
    }
    to {
      left: 120%;
    }
  }
`;

export const ImgCloud2 = styled.img`
  width: 18.7%;
  max-width: 12rem;
  min-width: 9.5rem;

  position: relative;
  top: -10rem;
  animation: slidein-reverse 30s linear infinite;
  z-index: 2;

  @keyframes slidein-reverse {
    from {
      left: 150%;
    }
    to {
      left: -250px;
    }
  }
`;

export const ImgCloud3 = styled.img`
  width: 40%;
  max-width: 20rem;
  min-width: 13rem;

  position: relative;
  bottom: 5rem;
  animation: slidein 15s linear infinite;
  z-index: 3;

  @keyframes slidein {
    from {
      left: -300px;
    }
    to {
      left: 150%;
    }
  }
`;
