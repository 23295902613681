const storage = sessionStorage;

const saveCart = (cart) => {
  const cartString = JSON.stringify(cart);
  sessionStorage.setItem('cart', cartString);
};

const getCart = () => {
  const cartString = storage.getItem('cart');
  return JSON.parse(cartString);
};

export default { saveCart, getCart };
