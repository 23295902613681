import FloatButton from "../../../../components/FloatButtonGroup/FloatButton";
import FloatButtonGroup from "../../../../components/FloatButtonGroup";
import CardapioSvg from '../../../../assets/images/cardapio.svg';


function FloatButtonHome() {
  return (
    <FloatButtonGroup>
      <FloatButton image={CardapioSvg} to="/cardapio" />
    </FloatButtonGroup>
  )
}

export default FloatButtonHome;