import { ModalStyle, Overlay } from './style';
import Button from '../Button';

function Modal({ children, handleIsOpen }) {
  function closeModal(e) {
    if (e.target.classList.contains('close')) {
      handleIsOpen('');
    }
  }
  return (
    <Overlay className="close" onClick={(e) => closeModal(e)}>
      <ModalStyle>
        {children}
        <Button className="close" small onClick={(e) => closeModal(e)}>
          ok!
        </Button>
      </ModalStyle>
    </Overlay>
  );
}

export default Modal;
