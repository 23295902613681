import SectionNotFound from './components/SectionNotFound';
import RelativeContainer from '../../components/RelativeContainer';

function NotFound() {
  return (
    <>
      <RelativeContainer>
        <SectionNotFound />
      </RelativeContainer>
    </>
  );
}

export default NotFound;
