import styled from 'styled-components';

const Button = styled.button`
  border: none;
  border-radius: var(--rounded-border-one);
  color: var(--white);
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: bold;
  padding: 1rem 2rem;
  transition: ease-in .3s;
  cursor: pointer;

  background-color: ${(props) => {
    switch (props.color) {
      case 'green':
        return 'var(--green)';
      case 'accent':
        return 'var(--accent-color)';
      default:
        return 'var(--primary-color)';
    }
  }};

  &:hover{
    background-color: ${(props) => {
      switch (props.color) {
        case 'green':
          return 'var(--accent-color-hover)';
        case 'accent':
          return 'var(--accent-color-hover)';
        default:
          return 'var(--primary-color-hover)';
      }
    }};
  }

  &:active{
    background-color: ${(props) => {
      switch (props.color) {
        case 'green':
          return 'var(--accent-color-active)';
        case 'accent':
          return 'var(--accent-color-active)';
        default:
          return 'var(--primary-color-active)';
      }
    }};
    transition: 0s;
  }

  ${(props) => {
    if (props.stretched) {
      return `
        width: 100%;
      `;
    }
    if (props.small) {
      return `
        font-size: 0.875rem;
        font-weight: normal;
        padding: 0.5rem 1rem;
      `;
    }
  }}
`;

export default Button;
