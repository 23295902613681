import styled from 'styled-components';

export const Nav = styled.nav`
  position: sticky;
  border-bottom: 2px solid #333333;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;

  display: flex;
  justify-content: center;

  background-color: var(--white);

  ul {
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: start;
    padding: 0;
    overflow-x: scroll;

    ::-webkit-scrollbar {
      height: 7px;
      border-radius: 6px;
      background: var(--white);
    }
  }

  /* li {
    height: 2rem;
  } */

  a {
    display: block;
    text-decoration: none;
    color: var(--black);
    padding: 0 1rem;
    line-height: 3.5rem;
    font-weight: bold;
    border-right: 1px solid var(--gray-three);
    border-left: 1px solid var(--gray-three);
    transition: ease-in .1s;
  }
  
  a:hover{
    background-color: var(--gray-seven);
  }
`;
