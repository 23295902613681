import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  align-items: center;
  justify-content: space-evenly;

  section {
    scroll-margin-top: 3.5rem;
    padding: 24px 0;
  }

  h1 {
    text-align: center;
  }
`;

export const ProductsWrapper = styled.div`
  @media (min-width: 800px) {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
  }
  @media (min-width: 1150px) {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }
`;
