import { useContext } from 'react';
import { MenuContext } from '../../../../contexts/MenuContext';

import { Container, ProductsWrapper } from './style';

import Card from '../../../../components/Card';

function SectionProducts() {
  const { getMenu } = useContext(MenuContext);
  const menu = getMenu();

  return (
    <Container>
      {menu.map((category) => {
        return (
          <section key={category.id} id={category.id}>
            <h1>{category.title}</h1>
            <ProductsWrapper>
              {category.products.map((product) => {
                return (
                  <Card
                    key={product.id}
                    id={product.id}
                    title={product.title}
                    price={product.price.toFixed(2)}
                    description={product.description}
                  />
                );
              })}
            </ProductsWrapper>
          </section>
        );
      })}
    </Container>
  );
}

export default SectionProducts;
