import { useContext } from "react"
import { Nav } from "./style"
import { MenuContext } from "../../../../contexts/MenuContext"

function MenuNavigation() {
  const { getCategories } = useContext(MenuContext)

  return (
    <Nav>
      <ul>
        {
          getCategories().map(categorie => {
            return (
              <li key={categorie.id}>
                <a href={`#${categorie.id}`}>
                  {categorie.title}
                </a>
              </li>
            )
          })
        }
      </ul>
    </Nav>
  )
}

export default MenuNavigation;