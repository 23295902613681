import styled from 'styled-components';

export const Container = styled.div`
  max-width: 340px;
  min-width: 300px;
  background-color: #ffffff;
  padding: 10px;
  margin-bottom: 16px;
  overflow-x: hidden;

  display: grid;
  grid-template-areas:
    'header header'
    'sideImage description'
    '. footer';
  grid-template-columns: 75px 190px;
  grid-template-rows: 20px min(110px, 1fr) 20px;

  gap: 15px;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  border-radius: 10px;

  @media (min-width: 420px) {
    grid-template-columns: 75px 240px;
    gap: 10px;
    padding: 16px;
  }
  @media (min-width: 800px) {
    padding: 16px 16px 8px;
  }
`;

export const Image = styled.div`
  width: 75px;
  height: 75px;

  margin-top: 12px;
  border-radius: 10px;
  background-color: rgb(150, 150, 150);
  grid-area: sideImage;
`;

export const CardHeader = styled.div`
  grid-area: header;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  font-family: Josefin Sans, Open Sans;

  margin: 0;

  h3 {
    margin: 10px 0 0;
  }

  p {
    margin: 0;
  }

  @media (min-width: 800px) {
    width: 95%;
  }
`;

export const Title = styled.h3`
  width: 11rem;

  display: -webkit-box;

  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Price = styled.h3`
  color: var(--primary-color);
  white-space: nowrap;
`;

export const Description = styled.div`
  grid-area: description;
  width: 100%;
  max-height: 4.2rem;
  font-size: 12px;
  font-weight: 400;

  display: -webkit-box;

  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  padding: 12px 0px 0px 10px;
  margin-bottom: 10px;
`;

export const CardAmount = styled.div`
  grid-area: footer;
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  justify-self: flex-end;

  font-family: poppins, open sans;
  font-size: 12px;
  font-weight: 400;
`;

export const InputContainer = styled.div`
  width: 90px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  font-size: 20px;
`;

export const TextBox = styled.input`
  width: 15px;
  text-align: center;
`;

export const QtdButton = styled.button`
  border: none;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  transition: background 0.8s;

  background-color: ${(props) => {
    switch (props.color) {
      case 'more':
        return '#D0F2C0';
      case 'less':
        return '#FFC0C0';
      default:
        return 'gray';
    }
  }};

  &:hover {
    background-color: ${(props) => {
      switch (props.color) {
        case 'more':
          return '#71d841';
        case 'less':
          return '#ff7c7c';
        default:
          return 'gray';
      }
    }};
  }

  &:active {
    background-color: ${(props) => {
      switch (props.color) {
        case 'more':
          return '#b0e995';
        case 'less':
          return '#ff9e9e';
        default:
          return 'gray';
      }
    }};
    transition: 0s;
  }

`;
