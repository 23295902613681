import styled from 'styled-components';

export const Container = styled.button`
  background-color: var(--gray);
  border: none;
  border-radius: 10px 0px 0px 10px;
  color: var(--gray-five);
  font-family: Poppins;
  padding: 0.7rem 0.8rem;
  text-transform: uppercase;
  transition-duration: 1s;

  &:hover {
    background-color: var(--gray-five);
    padding: 0.7rem 1.5rem;
    color: var(--white);
  }

  @media (min-width: 800px) {
    display: none;
  }
`;

export const Img = styled.img`
  width: 2.4rem;
`;
