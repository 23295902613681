import styled from 'styled-components';

export const Container = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (min-width: 1000px) {
    padding: 6rem;
    flex-direction: row;
    div {
      max-width: 30rem;
    }
  }
`;

export const Image = styled.img`
  width: 20rem;

  @media (min-width: 1000px) {
    width: 30rem;
  }
`;
