import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  :root {
    --primary-color: #F1B104;
    --secondary-color: #FFEEB0;
    --accent-color: #118D25;

    --accent-color-hover: #0a5015;
    --primary-color-hover: #ae8003;

    --accent-color-active: #084111;
    --primary-color-active: #8d6702;

    --white: #FFFFFF;
    --gray: #f0f0f0;
    --gray-one: #FDFDFD;
    --gray-two: #F4F4F4;
    --gray-three: #D2D2D2;
    --gray-four: #8E8E8E;
    --gray-five: #333333;
    --gray-six: #E3E3E3;
    --gray-seven: #cccccc;
    --black: #000000;
    --green: #118D25;

    --rounded-border-one: 5px;
    --rounded-border-two: 10px;
    --rounded-border-three: 15px;

    scroll-behavior: smooth;
    
  }

  body {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  * {
    font-family: 'Poppins', sans-serif;

    ::-webkit-scrollbar {
      background: var(--gray-two);
      width: 10px;
    }
    ::-webkit-scrollbar-track {
      background-color: transparent;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background: var(--gray-four);
    }
  }

  h1, h2, h3, h4, h5, h6, button {
    font-family: 'Josefin Sans', sans-serif;
  }
`;

export default GlobalStyle;
