import { Link } from 'react-router-dom';
import Button from '../../../../components/Button/index';

function ContentBottomBar() {
  return (
    <Link to="cardapio">
      <Button>ver cardápio</Button>
    </Link>
  );
}

export default ContentBottomBar;
