import { Container, FlexDiv, HorariosDiv, Image} from './style'
import Wave  from '../../../../components/Wave/index.js';
import SectionHorarioImg from '../../../../assets/images/section-horarios.png';
import WaveTop from '../../../../assets/images/section-horarios-wave-top.png';
import WaveBottom from '../../../../assets/images/section-horarios-wave-bottom.png';

function SectionHorarios() {
  return (
    <>
    <Container id="schedule">
      <Wave src={WaveTop}/>
      <FlexDiv>
        <div>
          <h1>Horários de atendimento</h1>
          <HorariosDiv>
            <ul>
              <li>Segunda</li>
              <li>Terça</li>
              <li>Quarta</li>
              <li>Quinta</li>
              <li>Sexta</li>
            </ul>
            <div></div>
            <ul>
              <li>8h às 12h</li>
              <li>8h às 12h</li>
              <li>8h às 12h</li>
              <li>8h às 12h</li>
              <li>8h às 12h</li>
            </ul>
          </HorariosDiv>
        </div>
        <Image src={SectionHorarioImg} alt="Família fazendo refaição juntos, sentados a uma mesa redonda" />
      </FlexDiv>
      <Wave src={WaveBottom}/>
    </Container>
    </>
  );
}

export default SectionHorarios;
