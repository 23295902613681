import { Link } from 'react-router-dom';
import { Container, Img } from './style';

function FloatButton({ to, image, inPageRedirect }) {
  return (
    <>
      {inPageRedirect ? (
        <a href={to}>
          <Container>
            <Img src={image} alt={to} />
          </Container>
        </a>
      ) : (
        <Link to={to}>
          <Container>
            <Img src={image} alt={to} />
          </Container>
        </Link>
      )}
    </>
  );
}

export default FloatButton;
