import styled from 'styled-components';

export const TitleFormsPayment = styled.h2`
  margin: 6rem 0 3.5rem 0;
  text-align: center;
  font-size: 1.15rem;
  color: var(--gray-five);

  @media (min-width: 600px) {
    font-size: 1.48rem;
  }
`;

export const TitleFlags = styled.h4`
  text-align: center;
  margin-top: 3.5625rem;
  color: var(--gray-five);
`;

export const ContainerFlags = styled.div`
  display: flex;
  justify-content: center;

  img {
    width: 3rem;
    align-self: center;
    margin: 0 0.3rem;
    @media (min-width: 700px) {
      width: 4rem;
      margin: 0 0.4rem;
    }
  }
`;

export const ContainerPayment = styled.div`
  display: grid;
  max-width: 1300px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 1rem;
  align-self: center;

  @media (min-width: 500px) {
    display: flex;
    margin: 1rem;
    justify-content: space-evenly;
  }
  @media (min-width: 800px) {
    margin: 0 auto;
  }
`;
