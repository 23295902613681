import React, { useState } from 'react';
import { Nav, ListNav, Logo, MenuMobile } from './style.js';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo-seed.png';

function Navbar({ children }) {
  return (
    <Nav>
      <div>
        <Link to="/">
          <Logo src={logo} alt="Logo" />
        </Link>
        {children}
      </div>
    </Nav>
  );
}

export default Navbar;
